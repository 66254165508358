<template>
<div>
    <!-- <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
    <v-row style="margin-top: 0%" v-show="!$vuetify.breakpoint.mobile"></v-row> -->

    <v-container>
        <waiting-modal :title="title" :status="status" :active="active_waiting" @statusdailog="update_status_wait"></waiting-modal>
        <v-row class="align-center d-flex h-0 ">
            <v-col xl="3" lg="4" sm="8" class="">
                <checkmong-home-bar title="ข้อมูลสุขภาพ"></checkmong-home-bar>
            </v-col>
        </v-row>
        <br />
        <v-row class="align-center d-flex h-0 pb-20" style="justify-content: center;">
            <v-col xl="3" lg="4" sm="8">
                <v-expansion-panels style="border-radius: 10px;">
                    <v-expansion-panel>
                        <v-expansion-panel-header expand-icon="info_outline">
                            <v-row class="">
                                <v-col cols="2">
                                    <v-icon color="purple">
                                        person
                                    </v-icon>
                                </v-col>
                                <v-col cols="10" style="text-align: left;padding-top: 26px;">
                                    <v-row><span style="font-size: 16px;" class="pb-1">{{res_info.fullname}}</span></v-row>
                                    <v-row><span style="font-size: 11px;">อัพเดทล่าสุด ณ วันที่ <span class="text-purple">{{display_date}}</span></span></v-row>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-divider></v-divider>
                            <br>
                            <v-row>
                                <v-col cols="12" style="padding-left: 28px;">
                                    <v-row><span style="font-size: 15px;">เลขบัตรประจำตัวประชาชน : <span class="text-purple">{{res_info.idcard}}</span></span></v-row>
                                    <v-row><span style="font-size: 15px;">วัน/เดือน/ปี เกิด : <span class="text-purple">{{new Date(res_info.birthdate).toLocaleString('th-TH', { dateStyle: 'long'})}}</span></span></v-row>
                                    <v-row><span style="font-size: 15px;">เบอร์โทรศัพท์ : <span class="text-purple">{{res_info.phone_number}}</span></span></v-row>
                                    <v-row><span style="font-size: 15px;">ที่อยู่ : <span class="text-purple">{{res_info.full_address}}</span></span></v-row>
                                </v-col>
                            </v-row>
                            <br>
                        </v-expansion-panel-content>

                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row class="align-center d-flex h-0 pb-20" style="justify-content: center;">
            <v-col xl="3" lg="4" sm="8" style="text-align: right; margin-bottom: -22px;">
                <v-btn @click="$router.push('/patient_health_record_add')" tile depressed color="text-purple" style="background-color: #f0f2f5;">
                    <v-icon left style="color: #1F98BD;">
                        post_add
                    </v-icon>
                    เพิ่มข้อมูลสุขภาพ
                </v-btn>
            </v-col>
        </v-row>

        <v-row class="align-center d-flex h-0 pb-20" style="justify-content: center;">
            <v-col xl="3" lg="4" sm="8">
                <v-card style="border-radius: 15px" elevation="1">
                    <v-card-text>
                        <v-row dense style="background-color: #ffffff; border-radius: 10px;">
                            <v-col cols="12">
                                <v-list-item-title>โรคประจำตัว</v-list-item-title>
                                <v-list-item-subtitle>
                                    <li v-for="(item) in congenital_ds">{{item}}</li>

                                </v-list-item-subtitle>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="6">
                                <v-list-item-title>อายุ</v-list-item-title>
                                <v-list-item-subtitle>{{age}}</v-list-item-subtitle>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="6">
                                <v-list-item-title>น้ำหนัก(kg)</v-list-item-title>
                                <v-list-item-subtitle>{{weight}}</v-list-item-subtitle>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="6">
                                <v-list-item-title>ส่วนสูง(cm)</v-list-item-title>
                                <v-list-item-subtitle>{{height}}</v-list-item-subtitle>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="6">
                                <v-list-item-title>BMI (kg/m2)</v-list-item-title>
                                <v-list-item-subtitle>{{bmi}}</v-list-item-subtitle>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="6">
                                <v-list-item-title>AZQ Score</v-list-item-title>
                                <v-list-item-subtitle>{{score}}</v-list-item-subtitle>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="6">
                                <v-list-item-title>ความดัน(mmHg)</v-list-item-title>
                                <v-list-item-subtitle>{{systolic}} / {{diastolic}}</v-list-item-subtitle>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="6">
                                <v-list-item-title>ระดับน้ำตาลในเลือด(mg/dL)</v-list-item-title>
                                <v-list-item-subtitle>{{sugar}}</v-list-item-subtitle>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="6">
                                <v-list-item-title>ชีพจร(ครั้ง/นาที)</v-list-item-title>
                                <v-list-item-subtitle>{{hr}}</v-list-item-subtitle>
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="6">
                                <v-list-item-title>ชีพจรสม่ำเสมอ</v-list-item-title>
                                <v-list-item-subtitle>{{regular_hr}}</v-list-item-subtitle>
                                <v-divider></v-divider>
                            </v-col>

                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="align-center d-flex h-0 pb-20" style="justify-content: center;">
            <v-col xl="3" lg="4" sm="8">
                <v-card style="border-radius: 15px ;    padding: 13px;" elevation="1">
                    <v-tabs show-arrows v-model="tab">
                        <v-tabs-slider color="purple lighten-5"></v-tabs-slider>
                        <v-tab :key="1" >AZQ</v-tab>
                        <v-tab :key="2" >น้ำหนัก</v-tab>
                        <v-tab :key="3" >ความดันบน</v-tab>
                        <v-tab :key="4" >ความดันล่าง</v-tab>
                        <v-tab :key="5" >ระดับน้ำตาล</v-tab>
                        <v-tab :key="6" >ชีพจร</v-tab>
                      </v-tabs>
                      <v-tabs-items v-model="tab">
                        <v-tab-item :key="1">
                          <v-card flat>
                            <LineChart :chartData="chartData_asz"/>
                          </v-card>
                        </v-tab-item>
                        <v-tab-item :key="2">
                            <v-card flat>
                              <LineChart :chartData="chartData_weight"/>
                            </v-card>
                          </v-tab-item>
                          <v-tab-item :key="3">
                            <v-card flat>
                              <LineChart :chartData="chartData_systolic"/>
                            </v-card>
                          </v-tab-item>
                          <v-tab-item :key="4">
                            <v-card flat>
                              <LineChart :chartData="chartData_diastolic"/>
                            </v-card>
                          </v-tab-item>
                          <v-tab-item :key="5">
                            <v-card flat>
                              <LineChart :chartData="chartData_sugar"/>
                            </v-card>
                          </v-tab-item>
                          <v-tab-item :key="6">
                            <v-card flat>
                              <LineChart :chartData="chartData_hr"/>
                            </v-card>
                          </v-tab-item>
                      </v-tabs-items>
                </v-card>

            </v-col>
        </v-row>
        <br>
        <br>
        <br>
        <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center " style="padding-top: 15px">
            <v-col lg="3" sm="8" class="mx-auto" style="text-align: left;">
                <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                    <v-icon small>west</v-icon>
                </v-btn>
                &nbsp; &nbsp; &nbsp; &nbsp;
            </v-col>
    
        </v-row>
    
        <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
            <v-row class="align-left">
                <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl ml-6">
                    <v-icon small>west</v-icon>
                </v-btn>
            </v-row>
        </v-footer>
    </v-container>
</div>
</template>

<script>
import CheckmongHomeBar from "@/components/CheckmongHomeBar.vue";
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
import WaitingModal from "@/components/WaitingModal.vue";
import LineChart from '@/components/Line.vue'
export default {
    name: "calendar-patient",
    components: {
        CheckmongHomeBar,
        WaitingModal,
        LineChart,
    },
    data: () => ({
        tab:null,
        display_date:'',
        res_info: {},
        update_status_wait: false,
        active_waiting: false,
        title: '',
        status: '',
        congenital_ds: ['ไม่มี'],
        age: '-',
        patient_health_records: '-',
        height: '-',
        bmi: '-',
        score: '-',
        systolic: '-',
        diastolic:'-',
        sugar: '-',
        hr: '-',
        regular_hr: '-',
        health_records: {},
        last_update: '-',
        weight: '-',
        chartData_asz: {
          labels: [],
          datasets: [
            {
              label: 'AZQ',
              backgroundColor: '#f87979',
            //   data: [40, 39, 10, 40, 39, 80, 40]
              data: []
            }
          ]
        },
        chartData_weight: {
          labels: [],
          datasets: [
            {
              label: 'น้ำหนัก',
              backgroundColor: '#f87979',
            //   data: [40, 39, 10, 40, 39, 80, 40]
              data: []
            }
          ]
        },
        chartData_systolic: {
          labels: [],
          datasets: [
            {
              label: 'ความดันบน',
              backgroundColor: '#f87979',
            //   data: [40, 39, 10, 40, 39, 80, 40]
              data: []
            }
          ]
        },
        chartData_diastolic: {
          labels: [],
          datasets: [
            {
              label: 'ความดันล่าง',
              backgroundColor: '#f87979',
            //   data: [40, 39, 10, 40, 39, 80, 40]
              data: []
            }
          ]
        },
        chartData_sugar: {
          labels: [],
          datasets: [
            {
              label: 'ระดับน้ำตาลในเลือด',
              backgroundColor: '#f87979',
            //   data: [40, 39, 10, 40, 39, 80, 40]
              data: []
            }
          ]
        },
        chartData_hr: {
          labels: [],
          datasets: [
            {
              label: 'ชีพจร',
              backgroundColor: '#f87979',
            //   data: [40, 39, 10, 40, 39, 80, 40]
              data: []
            }
          ]
        },
    }),

    created() {
        
        this.getUserInfo()
        
    },
    mounted(){
        this.getPatientHealthRecords()

    },
    watch: {
      health_records(val){
        console.log('val:',val)
        console.log('patient_health_records:',this.health_records)
        if(val.height) {this.height = val.height}
        if(val.weight) {this.weight = val.weight}
        if(val.bmi) {this.bmi = val.bmi}
        if(val.azq_score_records){this.score = val.azq_score_records[val.azq_score_records.length-1].score}
        if(val.congenital_ds){this.congenital_ds = val.congenital_ds}
        if(val.patient_health_records.length > 0){
          this.last_update = val.patient_health_records[val.patient_health_records.length-1].date
          this.diastolic = val.patient_health_records[val.patient_health_records.length-1].diastolic
          this.regular_hr = val.patient_health_records[val.patient_health_records.length-1].regular_hr
          this.sugar = val.patient_health_records[val.patient_health_records.length-1].sugar
          this.systolic = val.patient_health_records[val.patient_health_records.length-1].systolic
          this.weight = val.patient_health_records[val.patient_health_records.length-1].weight
          this.hr = val.patient_health_records[val.patient_health_records.length-1].hr
          const options = { dateStyle: 'long', timeStyle:'short' };
          this.display_date = new Date(this.last_update).toLocaleString('th-TH', options);
          }
        this.age = val.age
        if(val.weight) {this.weight = val.weight}
        if (this.display_date == ''){
            console.log('======',this.display_date)
            const options = { dateStyle: 'long', timeStyle:'short' };
            this.display_date =  new Date(this.res_info.create_date).toLocaleString('th-TH', options);
        }
      }
    },
    methods: {

        async getPatientHealthRecords() {
            // this.active_waiting = true
            // this.title = 'กำลังอัพเดทข้อมูล',
                // this.status = 'loading'
            await Vue.axios.post(APIURL + '/get_patient_health_records', {
                "user_id": this.$store.state.login_detail.user_id
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data) {
                    // this.active_waiting = false
                    // this.$store.commit('setUserInfo',response.data);
                    this.health_records = response.data
                    //console.log('--------------')
                    //console.log(this.health_records)
                    this.updateChartData()
                    // this.district = response.data.district
                    // this.subdistrict_list = response.data.location_list
                    // this.location_id = response.data.location_list
                } else {
                    // this.active_waiting = false

                }

            })

        },
        updateChartData(){
            this.chartData_asz.labels= this.health_records.date_list_azq
            this.chartData_asz.datasets[0].data = this.health_records.azq_list_azq

            this.chartData_weight.labels= this.health_records.date_list_h
            this.chartData_weight.datasets[0].data = this.health_records.weight_list

            this.chartData_systolic.labels= this.health_records.date_list_h
            this.chartData_systolic.datasets[0].data = this.health_records.systolic_list

            this.chartData_diastolic.labels= this.health_records.date_list_h
            this.chartData_diastolic.datasets[0].data = this.health_records.diastolic

            this.chartData_sugar.labels= this.health_records.date_list_h
            this.chartData_sugar.datasets[0].data = this.health_records.sugar_list

            this.chartData_hr.labels= this.health_records.date_list_h
            this.chartData_hr.datasets[0].data = this.health_records.hr_list

            //console.log('chartData_asz:',this.chartData_asz)
            




        },
        async getUserInfo_update(){
                //console.log('this.$store.state.login_detail.user_id:',this.$store.state.login_detail.user_id)
                await Vue.axios.post(APIURL + '/get_user_info', {
                    "user_id": this.$store.state.login_detail.user_id
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    //console.log('get_user_info',response)
                    if (response.data) {
                        //console.log('get_user_info',response.data)
                        // this.active_waiting = false
                        this.$store.commit('setUserInfo',response.data);
                        // this.province = response.data.province
                        // this.district = response.data.district
                        // this.subdistrict_list = response.data.location_list
                        // this.location_id = response.data.location_list
                        // this.$router.push('/home_checkmong')
                    } else {
                        // this.active_waiting = false

                    }

                })

        },
        getUserInfo() {
            this.getUserInfo_update()
            this.res_info = this.$store.state.user_info
            console.log('this.res_info:',this.res_info)
        },
        save(date) {
            this.$refs.menu.save(date);
        },
        validate() {
            this.$refs.form.validate();
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>

<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: #ffffff;
    color: gray;
}

.custom-field {
    border-radius: 6px;
}

.v-picker__title {
    color: #ffffff;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #a84192;
}

.v-main__wrap {
    padding-bottom: 90px;
}

.position-relative {
    position: relative;
}

.v-date-picker-table .v-btn.v-btn--active {
    color: #ffffff;
    background-color: #a84192;
}

.theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
    color: #a84192 !important;
}

.theme--light.v-btn.v-btn--icon {
    color: #a84192 !important;
}

.v-dialog>.v-card>.v-card__text {
    padding: 0 24px 0px;
}

.v-list-item__title{
    font-size: 0.7rem;
    color: darkgray;
}

.theme--light.v-divider {
    border-color: rgb(202 198 198 / 12%);
}
</style>
